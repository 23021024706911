import React from 'react';
import { SRLWrapper } from 'simple-react-lightbox';

//styles
import { theme } from '@styles';
import { PhotosSection, Row, ImageWrapper } from './styles';
const { colors, fonts } = theme;

const options = {
  caption: {
    captionFontFamily: fonts.Secondary,
    captionFontSize: '16px',
    captionColor: colors.text,
    captionFontWeight: 300,
    showCaption: true,
  },
  settings: {
    transitionTimingFunction: 'ease-in-out',
    slideTransitionSpeed: 0.6,
    slideTransitionTimingFunction: [0.25, 0.75, 0.5, 1],
    slideAnimationType: 'slide',
    disablePanzoom: true,
    disableWheelControls: true,
    autoplaySpeed: 5000,
    hideControlsAfter: false,
  },
};

const Photos = ({ data }) => {
  return (
    <PhotosSection>
      <SRLWrapper options={options}>
        <Row>
          {data.map((reference, i) => (
            <ImageWrapper key={i}>
              <a href={reference.image.url} data-attribute="SRL">
                <img
                  src={reference.image.url}
                  alt={
                    reference.text
                      ? reference.text
                      : reference.image.alt
                      ? reference.image.alt
                      : ''
                  }
                />
              </a>
            </ImageWrapper>
          ))}
        </Row>
      </SRLWrapper>
    </PhotosSection>
  );
};

export default Photos;
