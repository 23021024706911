import React from 'react';
import { graphql } from 'gatsby';
import SEO from '@components/seo';
import BreadCrumb from '@components/breadcrumb';
import Photos from '@views/photos';

const RenderBody = ({ doc }) => {
  const photos = doc.images;

  return (
    <>
      <SEO
        title="Photos"
        uri="photos"
        desc="Our beautiful students have a great time at Dynamo."
      />
      <BreadCrumb name="News" />
      <Photos data={photos} />
    </>
  );
};

export default ({ data }) => {
  const doc = data.prismic.allPhotoss.edges.slice(0, 1).pop();

  if (!doc) return null;
  return <RenderBody doc={doc.node} />;
};

export const query = graphql`
  {
    prismic {
      allPhotoss {
        edges {
          node {
            images {
              image
              text
            }
          }
        }
      }
    }
  }
`;
