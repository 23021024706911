import styled from 'styled-components';
import { media } from '@styles';

export const PhotosSection = styled.section`
  width: 100%;
  height: auto;
  padding: 3rem 0;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`;

export const ImageWrapper = styled.div`
  flex: 0 0 25%;
  max-width: 25%;
  cursor: pointer;
  padding: 0 3px;

  ${media.giant`
    flex: 0 0 50%;
    max-width: 50%;
  `}
  ${media.thone`
    flex: 0 0 100%;
    max-width: 100%;
  `};
`;
